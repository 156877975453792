import React from 'react'
import clsx from 'clsx'
import { Close } from '@truphone/icons'

export default function Attachment(props) {
  return (
    <div
      className={clsx(
        'w-full bg-blue-900 text-white px-4 py-2 text-sm flex items-center justify-between rounded',
        props.className
      )}
    >
      <div className="flex items-center">{props.children}</div>
      {props.allowRemove && (
        <Close
          opacity={100}
          className="w-6 h-6 cursor-pointer opacity-100"
          onClick={() => props.onRemove()}
        />
      )}
    </div>
  )
}
