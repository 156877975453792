import React, { useState } from 'react'
import { ImageWrap } from '@truphone/gatsby-wrappers'
import { PrimaryButton } from '@truphone/buttons'
import { uppercaseString } from '@truphone/utils'
import { ArrowSimple } from '@truphone/icons'
import { SimpleModal } from '@truphone/modals'
import ReactTooltip from 'react-tooltip'
import { TooltipIcon } from './Icons'

function CountryItem(props) {
  return (
    <div
      className="flex items-center justify-between py-3 pl-4 hover-opacity"
      onClick={props.onClick}
    >
      <div className="flex items-center">
        {props.iso && (
          <div className="w-6 block border border-gray-300 mr-2">
            <img
              className="w-full"
              src={`/images/flags/${props.iso.toLowerCase()}.svg`}
              alt={props.name}
            />
          </div>
        )}
        <span>{props.name}</span>
      </div>

      <ArrowSimple className="arrow mr-2 w-4 h-4 -rotate-90 opacity-40" />
    </div>
  )
}

function WelcomeScreen(props) {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <div className="">
      <div className="text-white text-center mx-2 md:mx-0">
        <h1 className={`heron text-7xl text-7xl-resp my-2`}>{uppercaseString(props.title)}</h1>
        <h4 className="text-xl font-light">{props.description}</h4>
      </div>

      <div className="mt-8 mx-4 lg:mx-0">
        <div className="flex flex-col content-center">
          <div className="text-white text-center flex justify-center mx-2 mb-2 md:mx-0">
            <span className="italic">{props.countrySelectionTitle}</span>
            <TooltipIcon
              className="w-6 ml-2 -mt-1 text-white opacity-50 hover:text-white hover:opacity-100"
              focusable="false"
              aria-hidden="true"
              data-tip={props.countrySelectionTooltip}
            />
            <ReactTooltip className="w-64 sm:w-96 bg-black" />
          </div>
          <div className="bg-white w-full md:w-[550px] mx-auto mt-2 mb-6">
            <div className="md:columns-2 gap-0">
              {props.countrySelectionCountries.map((x) => (
                <div
                  className="border-grey-400 border-b-2 hover:bg-grey-100 cursor-pointer w-full"
                  onClick={() => props?.onCountrySelected(x.iso)}
                  key={x.iso}
                >
                  <CountryItem {...x} checked={props.selectedCountry === x.iso} />
                </div>
              ))}
              <div
                className="border-grey-400 border-b-2 hover:bg-grey-100 cursor-pointer  w-full"
                onClick={() => {
                  setModalOpen(true)
                }}
              >
                <CountryItem name={props.otherCountryItemText} />
              </div>
            </div>
          </div>
        </div>
        <SimpleModal
          isOpen={modalOpen}
          small={true}
          setClose={() => {
            setModalOpen(false)
          }}
        >
          <div className="flex flex-col justify-center">
            <div className="mx-auto">
              {props.otherCountryModalIcon && (
                <ImageWrap className="w-15 mb-4 -mt-3" {...props.otherCountryModalIcon} />
              )}
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: props.otherCountryModalContentNode?.childMarkdownRemark.html
              }}
            ></div>
            <PrimaryButton className="mt-4 cursor-pointer" onClick={() => setModalOpen(false)}>
              {props.otherCountryModalCtaText}
            </PrimaryButton>
          </div>
        </SimpleModal>
      </div>
    </div>
  )
}

export default WelcomeScreen
