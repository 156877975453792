import { ArrowSimple } from '@truphone/icons'
import { uppercaseString } from '@truphone/utils'
import { Form } from '@truphone/core-components'
import React, { useState } from 'react'
import Attachment from './Attachment'
import Button from './Button'
import { DollarIcon, LinkIcon } from './Icons'

export default function FormScreen(props) {
  const [submitted, setSubmitted] = useState(false)
  const [billAnalysis, setBillAnalysis] = useState(false)

  return (
    <div className="relative w-full max-w-7xl m-auto">
      <div className="flex flex-col lg:flex-row justify-center items-start">
        <div className="bg-white w-full rounded-sm p-4 md:p-8 lg:w-3/5">
          <Form
            {...props.form}
            defaultValues={props.defaultFormData}
            onSubmitted={() => setSubmitted(true)}
            renderAttachments={() => {
              return (
                <>
                  <Attachment>
                    <LinkIcon className="text-white w-5 h-5 mr-4" />
                    {props.pdfAttachmentFilename}
                  </Attachment>
                  {billAnalysis && (
                    <Attachment
                      className="mt-4"
                      allowRemove={true}
                      onRemove={() => setBillAnalysis(false)}
                    >
                      <DollarIcon className="text-white w-5 h-5 mr-4" />
                      {props.billAnalysisText}
                    </Attachment>
                  )}
                </>
              )
            }}
            appendFields={async (fields) => {
              const reportUrl = await props.generateReport(fields)
              fields['SixtySecsHomeCounty'] = props.homeCountry
              fields['SixtySecsOfficeLocations'] = props.officeLocations.join(', ')
              fields['SixtySecsTravelDestinations'] = props.travelDestinations.join(', ')
              fields['associatedFile'] = new URL(reportUrl, window.location).href
              fields['requestedBillAnalysis'] = billAnalysis

              return fields
            }}
          />
        </div>
        {!billAnalysis && !submitted && (
          <div className="sticky top-0 flex flex-col bg-white w-full mt-8 rounded-sm py-8 px-6 lg:mt-0 lg:w-1/4 lg:ml-8">
            <h4 className="text-sm tracking-widest font-semibold mb-4">
              {uppercaseString(props.asideTitle)}
            </h4>
            <div
              className="text-base mb-6"
              dangerouslySetInnerHTML={{ __html: props.asideContentNode?.childMarkdownRemark.html }}
            ></div>
            <Button
              secondary
              className="w-full mt-auto flex items-center"
              onClick={() => setBillAnalysis(true)}
            >
              <ArrowSimple className="w-4 h-4 text-white mr-4 rotate-90" />
              {props.asideCtaText}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
