import React from 'react'

export function SearchIcon(props) {
  const { className, ...rest } = props
  return (
    <svg className={className} {...rest}>
      <path d="M4.036 10.189a6.16 6.16 0 016.153-6.153 6.16 6.16 0 016.152 6.153 6.16 6.16 0 01-6.152 6.152 6.16 6.16 0 01-6.153-6.152m17.666 9.353l-4.746-4.745a8.19 8.19 0 10-2.16 2.16l4.746 4.745a1.019 1.019 0 001.44 0l.72-.72a1.02 1.02 0 000-1.44"></path>
    </svg>
  )
}

export function CloseIcon(props) {
  const { className, ...rest } = props
  return (
    <svg className={className} {...rest}>
      <path
        fillRule="evenodd"
        d="M26.098 23.785a1.637 1.637 0 11-2.313 2.315L18 20.314 12.215 26.1a1.637 1.637 0 01-2.313-2.315L15.686 18l-5.784-5.785A1.637 1.637 0 1112.215 9.9L18 15.686l5.785-5.784c.64-.64 1.675-.64 2.313 0 .64.636.64 1.674 0 2.313L20.314 18l5.784 5.785zM18 0C8.06 0 0 8.06 0 18s8.06 18 18 18 18-8.06 18-18S27.94 0 18 0z"
      ></path>
    </svg>
  )
}

export function TooltipIcon(props) {
  const { className, ...rest } = props
  return (
    <svg className={className} viewBox="0 0 24 24" {...rest}>
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
      ></path>
    </svg>
  )
}

export function LinkIcon(props) {
  const { className, ...rest } = props
  return (
    <svg className={className} viewBox="0 0 21 21" {...rest}>
      <path
        fill="currentColor"
        d="M20.415 3.404c.78.781.78 2.053.001 2.834L15.46 11.2c-.381.38-.884.588-1.418.588a1.99 1.99 0 01-1.414-.586l-.002-.002 1.416-1.417h.002L19 4.822l-2.832-2.835-4.958 4.96-1.417 1.418a2.01 2.01 0 010-2.834L14.748.569c.76-.758 2.074-.758 2.832-.002l2.834 2.837zM9.792 14.034l1.416-1.417c.78.782.78 2.054.001 2.834l-4.958 4.962c-.38.38-.883.587-1.416.587a1.99 1.99 0 01-1.415-.585L.585 17.578a2.01 2.01 0 010-2.834l4.957-4.961c.76-.76 2.074-.76 2.832-.002l.002.002L6.959 11.2h-.002l-4.955 4.96 2.834 2.835 4.956-4.96h-.001.001zm-2.124-.708a1.002 1.002 0 010-1.418l.85-.85 3.398-3.402a.999.999 0 011.417 0 1.003 1.003 0 010 1.418l-4.25 4.252a.999.999 0 01-1.415 0z"
      ></path>
    </svg>
  )
}

export function DollarIcon(props) {
  const { className, ...rest } = props
  return (
    <svg className={className} viewBox="0 0 21 21" {...rest}>
      <path
        fill="currentColor"
        d="M10.01 8.86l.271.05c1.229.139 3.709 1.149 3.719 3.88v.019c-.01 1.821-1.28 3.351-3 3.79V18H9v-1.38c-1.769-.39-3-1.821-3-3.62h2c0 1.19 1.031 1.73 1.99 1.73 1.1 0 2.01-.87 2.01-1.931-.01-1.66-1.95-1.91-2.04-1.919l-.27-.05c-1.23-.14-3.72-1.151-3.72-3.901 0-1.83 1.29-3.38 3.03-3.799V2h2v1.13c1.72.45 3 2.009 3 3.87h-2c0-1.08-.92-2-2.01-2-1.12 0-2.02.87-2.02 1.929 0 1.62 1.83 1.901 2.04 1.931M10 0C4.481 0 0 4.48 0 10c0 5.519 4.481 10 10 10 5.52 0 10-4.481 10-10 0-5.52-4.48-10-10-10"
      ></path>
    </svg>
  )
}
