import React, { useMemo, useState } from 'react'
import { uppercaseString } from '@truphone/utils'
import { ArrowSimple } from '@truphone/icons'
import { SearchIcon, CloseIcon } from './Icons'
import { SimpleModal } from '@truphone/modals'

function Searchbar(props) {
  const searchTermChanged = (value) => {
    if (
      typeof props.onSearchTermChanged !== 'undefined' &&
      typeof props.onSearchTermChanged === 'function'
    ) {
      props.onSearchTermChanged(value)
    }
  }
  return (
    <div className="relative rounded-2xl shadow-md bg-white">
      <SearchIcon className="absolute opacity-40 top-2 left-3 w-5 h-5" />
      <input
        className="w-full h-9 focus:ring-transparent border-none bg-transparent py-1 px-10 text-base rounded-none"
        type="text"
        placeholder={props.placeholder || 'Search locations'}
        onChange={(e) => searchTermChanged(e.target.value)}
        value={props.searchTerm}
      />
      {props.searchTerm && (
        <CloseIcon
          className="absolute opacity-40 top-2 right-4 w-5 h-5 cursor-pointer"
          viewBox="0 0 36 36"
          onClick={() => searchTermChanged('')}
        />
      )}
    </div>
  )
}

function SearchResults(props) {
  return (
    <div className="bg-white">
      <div className="flex justify-between text-sm tracking-wide p-4 font-semibold bg-grey-100 border-b border-grey-200">
        {props.searchResultsText}
      </div>
      <div className="h-auto max-h-[500px] flex flex-wrap overflow-auto transition-all">
        {props.filteredLocations
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((country) => {
            return (
              <CountryItem
                key={country.iso}
                {...country}
                selected={
                  props.lockedCountries.indexOf(country.iso) !== -1 ||
                  props.selectedCountries.indexOf(country.iso) != -1
                }
                disabled={props.lockedCountries.indexOf(country.iso) !== -1}
                onSelect={props.onCountrySelected}
              />
            )
          })}
      </div>
    </div>
  )
}

function CountryItem(props) {
  return (
    <div
      className={`w-full sm:w-1/2 flex items-center justify-between py-3 px-4 border-b border-grey-200`}
      onClick={() => !props.disabled && props.onSelect(props.iso)}
    >
      <div
        className={`flex items-center pointer-events-none ${props.disabled ? 'opacity-40' : ''}`}
      >
        {props.iso && (
          <div className="w-6 border border-gray-300">
            <img
              className="w-full block"
              src={`/images/flags/${props.iso.toLowerCase()}.svg`}
              alt={props.name}
            />
          </div>
        )}
        <span className="text-sm ml-2">{props.name}</span>
      </div>

      <input
        className={`text-blue-100 rounded-sm ${props.disabled ? 'opacity-40' : ''}`}
        type="checkbox"
        disabled={props.disabled}
        checked={props.selected}
        onChange={() => {}}
      />
    </div>
  )
}

function groupBy(items, key) {
  return items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item]
    }),
    {}
  )
}

function LocationsScreen(props) {
  const [regions, setRegions] = useState([])
  const [regionNames, setRegionNames] = useState([])
  const [selectedRegion, setSelectedRegion] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  const [searchTerm, setSearchTerm] = useState()

  const toggleRegionOpen = (i) => {
    setSelectedRegion((prev) => {
      if (prev === i) {
        return -1
      }

      return i
    })
  }

  useMemo(() => {
    const regions = groupBy(props.locations, 'region')
    const sortedRegionNames = Object.getOwnPropertyNames(regions).sort((a, b) => a.localeCompare(b))

    regions[props.popularLocationsText] = [
      'AU',
      'DE',
      'ES',
      'FR',
      'GB',
      'HK',
      'NL',
      'PL',
      'US'
    ].map((iso) => props.locations.find((x) => x.iso == iso))

    setRegions(regions)
    setRegionNames([props.popularLocationsText, ...sortedRegionNames])
  }, [props.culture, props.popularLocationsText])

  return (
    <div className="flex flex-col md:flex-row">
      <div className="basis-1/2 px-4 mx-2 md:mx-0 text-center md:text-left">
        <div className="text-white">
          <h2 className="mb-6 font-medium text-base tracking-widest">
            {uppercaseString(props.topTitle)}
          </h2>
          <h3 className="mb-4 text-2xl font-light tracking-tighter">{props.title}</h3>
          <p className="text-base">{props.description}</p>
        </div>
        <div
          onClick={() => setOpenModal(true)}
          className="cursor-pointer flex w-full bg-white rounded-sm mt-6 py-2 px-4 md:w-1/2 items-center justify-between font-medium text-sm tracking-widest shadow-md"
        >
          {uppercaseString(props.locationsText)}
          <span className="bg-green-400 rounded-sm text-white text-base py-1 px-3">
            {props.lockedCountries.length + props.selectedCountries.length}
          </span>
        </div>

        {openModal && (
          <SimpleModal
            isOpen={openModal}
            setClose={() => {
              setOpenModal(false)
            }}
          >
            <h3 className="text-2xl mb-4 text-left"> {uppercaseString(props.locationsText)}</h3>
            <div className="flex flex-wrap max-h-96 overflow-y-auto">
              {props.locations
                .filter((c) =>
                  props.selectedCountries.concat(props.lockedCountries).includes(c.iso)
                )
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((country) => {
                  return (
                    <CountryItem
                      key={country.iso}
                      {...country}
                      selected={
                        props.lockedCountries.indexOf(country.iso) !== -1 ||
                        props.selectedCountries.indexOf(country.iso) != -1
                      }
                      disabled={props.lockedCountries.indexOf(country.iso) !== -1}
                      onSelect={props.onCountrySelected}
                    />
                  )
                })}
            </div>
          </SimpleModal>
        )}
      </div>
      <div className="basis-1/2 px-4 mx-2 sm:mx-0 min-h-[710px]">
        <div className="mt-8 md:mt-0">
          <Searchbar
            searchTerm={searchTerm}
            onSearchTermChanged={setSearchTerm}
            placeholder={props.searchPlaceholder}
          />
        </div>

        <div className="mt-8">
          {searchTerm && (
            <SearchResults
              onCountrySelected={props.onCountrySelected}
              searchResultsText={props.searchResultsText}
              lockedCountries={props.lockedCountries}
              selectedCountries={props.selectedCountries}
              filteredLocations={
                props.locations &&
                props.locations.filter((x) =>
                  x.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
              }
            />
          )}

          {!searchTerm &&
            regionNames &&
            regionNames.length > 0 &&
            regionNames.map((region, i) => {
              return (
                <div key={region} className="bg-white">
                  <div
                    onClick={() => toggleRegionOpen(i)}
                    className="flex justify-between text-sm tracking-wide p-4 font-semibold bg-grey-100 border-b border-grey-200"
                  >
                    {region}
                    <div className="flex">
                      {/* <input
                        className="mr-2 text-blue-100 rounded-sm"
                        type="checkbox"
                        checked={true}
                      /> */}
                      <ArrowSimple
                        className={`w-4 h-4 ${selectedRegion === i ? '-rotate-180' : ''}`}
                      />
                    </div>
                  </div>
                  <div
                    className={`${
                      selectedRegion === i ? 'h-auto' : 'h-0'
                    } flex flex-wrap max-h-80 overflow-auto transition-all`}
                  >
                    {regions[region]
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((country) => {
                        return (
                          <CountryItem
                            key={country.iso}
                            {...country}
                            selected={
                              props.lockedCountries.indexOf(country.iso) !== -1 ||
                              props.selectedCountries.indexOf(country.iso) != -1
                            }
                            disabled={props.lockedCountries.indexOf(country.iso) !== -1}
                            onSelect={props.onCountrySelected}
                          />
                        )
                      })}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default LocationsScreen
