import React from 'react'

export default function Button({ disabled, outline, secondary, className, children, ...props }) {
  const css = outline
    ? 'text-black bg-white hover:bg-gray-100 border-black border-2'
    : disabled
    ? 'bg-gray-300 text-gray-500'
    : secondary
    ? 'text-white bg-blue-900 bg-blue-700-hover'
    : 'text-white bg-blue-100 hover:bg-blue-700'

  return (
    <button
      className={`font-bold py-3 md:py-2 px-4 rounded-lg ${css} ${className}`}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}
