import React, { useEffect, useRef, useState } from 'react'
import { uppercaseString } from '@truphone/utils'

function CustomChallenge(props) {
  const ref = useRef()
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState('')

  useEffect(() => {
    if (editing) {
      ref.current.focus()
    }
  }, [editing])

  const addChallenge = () => {
    if (value && props.onChallengeAdded) {
      props.onChallengeAdded(value)
    }
    setEditing(false)
    setValue('')
  }

  const keyPress = (e) => {
    if (e.key === 'Enter') {
      addChallenge()
    }
  }

  const blur = () => {
    addChallenge()
  }

  return (
    <div className="w-full p-4 cursor-pointer custom-grid">
      <div
        className="bg-white rounded-sm h-full flex items-center"
        onClick={() => setEditing(true)}
      >
        {editing && (
          <textarea
            ref={ref}
            className="w-full h-full p-4 text-sm font-semibold resize-none"
            maxLength={120}
            placeholder={props.placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyPress={keyPress}
            onBlur={blur}
          />
        )}

        {!editing && (
          <div className="py-6 flex items-center">
            <svg
              className="ml-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 18a1 1 0 1 1-2 0v-5H6a1 1 0 1 1 0-2h5V6a1 1 0 1 1 2 0v5h5a1 1 0 1 1 0 2h-5v5zm6.778-13.778c-4.296-4.296-11.26-4.296-15.556 0-4.296 4.296-4.296 11.26 0 15.556 4.296 4.296 11.26 4.296 15.556 0 4.296-4.296 4.296-11.26 0-15.556z"
                fill="#0D313F"
                fillRule="evenodd"
                fillOpacity=".6"
              />
            </svg>
            <span className="w-full ml-2 text-sm font-semibold opacity-60">
              {props.placeholder}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

function ChallengeScreen(props) {
  return (
    <>
      <div className="text-white text-center mx-2 md:mx-auto">
        <h2 className="mb-6 font-medium text-base tracking-widest">
          {uppercaseString(props.topTitle)}
        </h2>
        <h3 className="mb-4 text-2xl font-light tracking-tighter">{props.title}</h3>
        <p className="text-base">{props.description}</p>
      </div>

      <div className="mx-auto my-8">
        <div className="grid-layout md:flex">
          {props.challenges?.map((x) => (
            <div
              key={x.value}
              className="w-full p-4 cursor-pointer"
              onClick={() => props.onChallengeSelected(x)}
            >
              <div className="bg-white rounded-sm h-full p-4 flex items-center">
                <span
                  className="w-full text-sm font-semibold break-words"
                  style={{ maxWidth: 'calc(100% - 32px)' }}
                >
                  {x.text}
                </span>
                <div className="w-8 pl-4 shrink-0">
                  <input
                    className="text-blue-100 rounded-sm pointer-events-none"
                    type="checkbox"
                    checked={
                      props.selectedChallenges &&
                      !!props.selectedChallenges.find((y) => y.value === x.value)
                    }
                    onChange={() => props.onChallengeSelected(x)}
                  />
                </div>
              </div>
            </div>
          ))}

          <CustomChallenge
            placeholder={props.addYourOwn}
            onChallengeAdded={props.onChallengeAdded}
          />
        </div>
      </div>
    </>
  )
}

export default ChallengeScreen
