import React, { useEffect, useMemo, useState } from 'react'
import ChallengeScreen from './components/ChallengeScreen'
import FormScreen from './components/FormScreen'
import Layout from './components/Layout'
import LocationsScreen from './components/LocationsScreen'
import ResultsScreen from './components/ResultsScreen'
import ScrollToTop from './components/ScrollToTop'
import WelcomeScreen from './components/WelcomeScreen'

function SixtySecondsContainer(props) {
  const [homeCountry, setHomeCountry] = useState(null)
  const [businessChallenges, setBusinessChallenges] = useState(props.businessChallenges.challenges)
  const [selectedBusinessChallenges, setSelectedBusinessChallenges] = useState([])
  const [userChallenges, setUserChallenges] = useState(props.userChallenges.challenges)
  const [selectedUserChallenges, setSelectedUserChallenges] = useState([])

  const locations = useMemo(
    () =>
      require(`../data/countries-${props.lang}.json`).map((translation) => {
        return {
          iso: translation.ISO,
          name: translation['Country Name'],
          region: translation.region
        }
      }),
    [props.culture]
  )

  const [selectedOfficeLocations, setSelectedOfficeLocations] = useState([])
  const [selectedTravelDestinations, setSelectedTravelDestinations] = useState([])

  const [score, setScore] = useState('')
  const [step, setStep] = useState(0)

  const [defaultFormData, setDefaultFormData] = useState({})

  const prev = () => {
    if (step > 0) {
      setStep(step - 1)
    }
  }

  const next = () => {
    if (step < 5) {
      setStep(step + 1)
    }
  }

  const toggleChallenge = (challenge, list) => {
    const newState = [...list]
    const index = newState.indexOf(challenge)
    if (index != -1) {
      newState.splice(index, 1)
    } else {
      newState.push(challenge)
    }

    return newState
  }

  useEffect(() => {
    var params = new URLSearchParams(window.location.search)
    if (params.has('homeCountry')) {
      setHomeCountry(params.get('homeCountry'))
      setStep(1)
    }

    if (params.has('companySize')) {
      setDefaultFormData({
        Company_Size__c: params.get('companySize')
      })
    }
  }, [])

  useEffect(() => {
    const locationCount =
      (homeCountry ? 1 : 0) + selectedOfficeLocations.length + selectedTravelDestinations.length

    if (locationCount < 2) {
      setScore('notSuited')
    } else {
      let points = 20
      if (locationCount > 6) {
        points = 60
      } else if (locationCount > 4) {
        points = 40
      }

      if (selectedBusinessChallenges.length > 2) {
        points += 25
      } else if (selectedBusinessChallenges.length == 2) {
        points += 20
      } else if (selectedBusinessChallenges.length > 0) {
        points += 10
      }

      if (selectedUserChallenges.length > 2) {
        points += 25
      } else if (selectedUserChallenges.length == 2) {
        points += 20
      } else if (selectedUserChallenges.length > 0) {
        points += 10
      }

      setScore(points > 50 ? 'perfect' : 'suited')
    }
  }, [
    homeCountry,
    selectedBusinessChallenges,
    selectedUserChallenges,
    selectedOfficeLocations,
    selectedTravelDestinations
  ])

  const getCountryNameFromIso = (iso) => locations.find((x) => x.iso === iso)?.name

  const generateReport = async (fields) => {
    let requestBody = {
      ReportHtml: props.report.reportHtml.url,
      ReportHtmlSlim: props.report.reportHtmlSlim.url,
      CompanyName: fields.company,
      Fit: score < 50 ? props.report.good : props.report.great,
      Improvement: score < 50 ? props.report.some : props.report.significant,
      HomeCountry: getCountryNameFromIso(homeCountry),
      OfficeLocations: selectedOfficeLocations.map(getCountryNameFromIso).join(', '),
      TravelDestinations: selectedTravelDestinations.map(getCountryNameFromIso).join(', '),
      CallDestinationsCovered: selectedOfficeLocations.length,
      CallDestinationsTotal: selectedOfficeLocations.length,
      TravelDestinationsCovered: selectedTravelDestinations.length,
      TravelDestinationsTotal: selectedTravelDestinations.length,
      PlanManagementCostManagement:
        selectedBusinessChallenges.find((c) => c.value == 'costManagement') != null,
      PlanManagementMultiLingualSupport:
        selectedBusinessChallenges.find((c) => c.value == 'poorSupport') != null,
      PlanManagementPredictableBills:
        selectedBusinessChallenges.find((c) => c.value == 'unpredictableBills') != null,
      PlanManagementMultipleContracts:
        selectedBusinessChallenges.find((c) => c.value == 'multipleContracts') != null,
      PlanManagementFlexiblePricing:
        selectedBusinessChallenges.find((c) => c.value == 'inflexiblePricing') != null,
      PlanManagementInCountryServices:
        selectedBusinessChallenges.find((c) => c.value == 'inCountryServices') != null,
      UserManagementDualSim:
        selectedUserChallenges.find((c) => c.value == 'multipleDevices') != null,
      UserManagementNoSimSwapping:
        selectedUserChallenges.find((c) => c.value == 'swappingSims') != null,
      UserManagementPredictableFees:
        selectedUserChallenges.find((c) => c.value == 'roamingFees') != null,
      UserManagementEnterpriseService:
        selectedUserChallenges.find((c) => c.value == 'productivityImpact') != null,
      UserManagementMultipleNumbers:
        selectedUserChallenges.find((c) => c.value == 'internationalNumbers') != null,
      UserManagementTwentyFourHourSupport:
        selectedUserChallenges.find((c) => c.value == 'supportAllTimeZones') != null,
      UserAnswers: selectedBusinessChallenges
        .filter((x) => x.custom)
        .concat(selectedUserChallenges.filter((x) => x.custom))
        .map((x) => x.text)
    }

    let response = await fetch(
      `${process.env.API_ENDPOINT}pdfapi/sixtysecondsreportgatsby/?locale=${props.lang}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        body: JSON.stringify(requestBody)
      }
    )

    return await response.json()
  }

  return (
    <Layout
      showProgress={step < 6}
      prevText={props.prev}
      onPrev={prev}
      nextText={props.next}
      onNext={next}
      finishText={props.finish}
      step={step}
      totalSteps={5}
    >
      <ScrollToTop>
        {step === 0 && (
          <WelcomeScreen
            {...props.welcome}
            selectedCountry={homeCountry}
            onCountrySelected={(iso) => {
              setHomeCountry(iso)
              setStep(1)
            }}
          />
        )}
        {step === 1 && (
          <ChallengeScreen
            {...props.businessChallenges}
            challenges={businessChallenges}
            selectedChallenges={selectedBusinessChallenges}
            onChallengeSelected={(challenge) =>
              setSelectedBusinessChallenges((prev) => toggleChallenge(challenge, prev))
            }
            onChallengeAdded={(newChallenge) => {
              const challenge = { text: newChallenge, value: newChallenge, custom: true }
              setBusinessChallenges((prev) => {
                const newArr = [...prev]
                newArr.push(challenge)
                return newArr
              })
              setSelectedBusinessChallenges((prev) => toggleChallenge(challenge, prev))
            }}
          />
        )}
        {step === 2 && (
          <ChallengeScreen
            {...props.userChallenges}
            challenges={userChallenges}
            selectedChallenges={selectedUserChallenges}
            onChallengeSelected={(challenge) =>
              setSelectedUserChallenges((prev) => toggleChallenge(challenge, prev))
            }
            onChallengeAdded={(newChallenge) => {
              const challenge = { text: newChallenge, value: newChallenge, custom: true }
              setUserChallenges((prev) => {
                const newArr = [...prev]
                newArr.push(challenge)
                return newArr
              })
              setSelectedUserChallenges((prev) => toggleChallenge(challenge, prev))
            }}
          />
        )}
        {step === 3 && (
          <LocationsScreen
            {...props.officeLocations}
            locations={locations}
            lockedCountries={[homeCountry]}
            selectedCountries={selectedOfficeLocations}
            onCountrySelected={(iso) => {
              setSelectedOfficeLocations((prev) => {
                const newArr = [...prev]
                const index = prev.indexOf(iso)
                if (index === -1) {
                  newArr.push(iso)
                } else {
                  newArr.splice(index, 1)
                }

                return newArr
              })

              //Edge case, user selected country in travel destinations and then went back and selected it here.
              const index = selectedTravelDestinations.indexOf(iso)
              if (index !== -1) {
                setSelectedTravelDestinations((prev) => {
                  const newArr = [...prev]
                  newArr.splice(index, 1)
                  return newArr
                })
              }
            }}
          />
        )}
        {step === 4 && (
          <LocationsScreen
            {...props.travelDestinations}
            locations={locations}
            lockedCountries={[homeCountry, ...selectedOfficeLocations]}
            selectedCountries={selectedTravelDestinations}
            onCountrySelected={(iso) => {
              setSelectedTravelDestinations((prev) => {
                const newArr = [...prev]
                const index = prev.indexOf(iso)
                if (index === -1) {
                  newArr.push(iso)
                } else {
                  newArr.splice(index, 1)
                }

                return newArr
              })
            }}
          />
        )}
        {step === 5 && (
          <ResultsScreen
            {...props.results}
            score={score}
            aside={score != 'notSuited' ? props.results.aside : null}
            locationCount={
              [homeCountry, ...selectedOfficeLocations, ...selectedTravelDestinations].length
            }
            challengeCount={[...selectedBusinessChallenges, ...selectedUserChallenges].length}
            onShowForm={() => setStep(6)}
          />
        )}
        {step == 6 && (
          <FormScreen
            {...props.form}
            defaultFormData={defaultFormData}
            homeCountry={homeCountry}
            officeLocations={selectedOfficeLocations}
            travelDestinations={selectedTravelDestinations}
            businessChallenges={selectedBusinessChallenges}
            userChallenges={selectedUserChallenges}
            generateReport={async (fields) => await generateReport(fields)}
          />
        )}
      </ScrollToTop>
    </Layout>
  )
}

export default SixtySecondsContainer
