import React from 'react'
import Button from './Button'
import { uppercaseString } from '@truphone/utils'

function ResultsScreen(props) {
  const applyReplacementValues = (html) => {
    return html
      .replace(/{locationcount}/gi, props.locationCount)
      .replace(/{challengecount}/gi, props.challengeCount)
  }

  const showForm = (e) => {
    e.preventDefault()
    if (typeof props.onShowForm !== 'undefined' && typeof props.onShowForm === 'function') {
      props.onShowForm()
    }
  }

  return (
    <div className="relative w-full max-w-7xl m-auto">
      <div className="flex flex-col lg:flex-row justify-center">
        <div className="bg-white w-full text-center rounded-sm p-4 md:p-8 lg:w-3/5">
          <h2 className="heron font-light text-4xl tracking-widest">
            {uppercaseString(props[`${props.score}Title`])}
          </h2>
          <h3 className="font-normal text-2xl mt-4 mb-6">{props[`${props.score}Subtitle`]}</h3>
          {props[`${props.score}ContentNode`] && (
            <div
              className="link-blue whitespace-pre-line leading-normal"
              dangerouslySetInnerHTML={{
                __html: applyReplacementValues(
                  props[`${props.score}ContentNode`]?.childMarkdownRemark.html
                )
              }}
              onClick={(e) => e.target.localName === 'a' && showForm(e)}
            ></div>
          )}
        </div>
        {props.score !== 'notSuited' && (
          <div className="flex flex-col bg-white w-full mt-8 rounded-sm py-8 px-6 lg:mt-0 lg:w-1/4 lg:ml-8">
            <h4 className="text-sm tracking-widest font-medium mb-4">
              {uppercaseString(props.asideTitle)}
            </h4>
            <div
              className="text-base mb-6 whitespace-pre-line"
              dangerouslySetInnerHTML={{ __html: props.asideContentNode?.childMarkdownRemark.html }}
            ></div>
            <Button className="w-full mt-auto" secondary onClick={showForm}>
              {props.asideCtaText}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ResultsScreen
