import React from 'react'
import Button from './Button'
import { uppercaseString } from '@truphone/utils'
import { ArrowSimple } from '@truphone/icons'
import './sixty-seconds.css'

function Layout(props) {
  return (
    <div className="bg-blue-gradient flex content-center items-center justify-center min-h-screen pt-16 pb-24">
      <img className="absolute top-0 left-0" src="/images/sixty-seconds/circle.png" alt="" />
      <div className="w-full max-w-5xl z-10">{props.children}</div>
      {props.step > 0 && props.step <= props.totalSteps && (
        <div className="fixed bottom-0 bg-white py-2 w-full pr-4 sm:pl-4 z-30">
          <div className="w-full pl-4 sm:pl-0 sm:m-auto flex justify-between max-w-6xl">
            <Button className="cursor-pointer sm:px-16" onClick={props.onPrev} outline>
              <span className="hidden sm:block">{uppercaseString(props.prevText)}</span>
              <ArrowSimple className="block sm:hidden w-4 h-4 rotate-90"></ArrowSimple>
            </Button>
            {props.step < props.totalSteps && (
              <>
                <div className="flex grow-[2] shrink-[1] px-8 items-center justify-center">
                  <span className="text-center pr-4 basis-[100px] whitespace-nowrap">
                    {(props.step / props.totalSteps) * 100}%
                  </span>
                  <span className="absolute -top-1 sm:top-0 left-0 sm:relative w-full h-1 bg-grey-100">
                    <span
                      className="absolute h-1 top-0 left-0 bg-green-400 text-green-400 progress"
                      style={{ width: `${(props.step / props.totalSteps) * 100}%` }}
                    />
                  </span>
                </div>

                <Button
                  className="cursor-pointer w-1/2 sm:w-auto px-4 sm:px-16"
                  onClick={props.onNext}
                >
                  {props.step === props.totalSteps - 1
                    ? uppercaseString(props.finishText)
                    : uppercaseString(props.nextText)}
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Layout
